export * from './List';
export * from './Movie';
export * from './Movie.search';
export * from './Filter';
export * from './Init';
// export * from './User';

export const ListType = {
  USER: 'USER',
  MOVIES: 'MOVIES',
  SEARCH: 'SEARCH',
};
